import React from "react";
import Parser from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import PRICING_ACTIONS from "../../../../redux/actions/Pricing";
import * as Cookies from "js-cookie";
import putPricingState from "../../../../procedures/pricing/putPricingState";
import packPricingObject from "../../../../functions/pricing/packPricingObject";
import ContactUs from "../../../../components/contactUs";
import { navigate } from "gatsby";
import clsx from "clsx";
import styles2 from "../../../../components/contactUs/styles.module.css";

import EstimateText from "../../../../components/pricing/estimate/estimateText";
import EstimateValue from "../../../../components/pricing/estimate/estimateValue";
import "./styles.css";
import SignUpText from "../../../../components/pricing/signUp/signUpText";

const PricingMoreLayout = ({ section }) => {
  const Pricing = useSelector((state) => state.Pricing);
  const { patch } = Pricing;
  const dispatch = useDispatch();

  const setInitialEstimate = () => {
    const setAnswer = PRICING_ACTIONS.setInitialEstimate(1);
    dispatch(setAnswer);

    let pricingObject = packPricingObject(Pricing);
    pricingObject.initialEstimate = 1;
    const idCookie = Cookies.getJSON("opz-pricing-id");
    const id = idCookie ? idCookie.id : null;
    if (!id) {
      return () => {};
    }
    putPricingState(Pricing.id, pricingObject);
    navigate("/cost-to-make-an-app/dev-steps");
  };

  return (
    <>
      <div className="container">
        {!patch || patch === "" ? (
          <div>
            <center className={clsx("container", styles2.mycontainer2)}>
              <h3 style={{ textAlign: "center" }}>Bonuses from OpZoom</h3>
              <p>
                You need all the help you can get to succeed with your app.
                These bonuses are what we can do before we know you better.
              </p>
              <p>
                Here is what you can expect:
                <ul>
                  <li>
                    See the app development stages with sample documents, time
                    and cost estimates broken down by stage.
                  </li>
                  <li>
                    Sample apps from OpZoom including apps with <b>1 million</b>{" "}
                    downloads.
                  </li>
                  <li>
                    Product marketing formula with over <b>800 testimonials</b>{" "}
                    and <b>100,000 successful companies</b>
                  </li>
                  <li>
                    Formula to get funded by <b>StartApp Grant</b> at no cost to
                    you and the <b>StartApp Fund</b>
                    for upto <b>$100,000</b> when you are ready for an
                    investment.
                  </li>
                  <li>
                    Expert advice when you need it to accelerate your progress.
                  </li>
                </ul>
              </p>
            </center>

            <center className={clsx("container", styles2.mycontainer2)}>
              <h3 style={{ textAlign: "center" }}>Your Custom Estimate</h3>
              <p>
                This estimate is based on our experience with 100+ apps and your
                inputs. Your app could cost less if it can be simplified.
                Additional requirements are likely to cost more.
              </p>
              <p className="section__p d-flex justify-content-center flex-column">
                <div>
                  <EstimateText heading="Your Estimate" />
                  <EstimateValue />
                </div>
              </p>
              <p>
                <h4 style={{ textAlign: "center" }}>
                  100% money back guarantee
                </h4>
                Your app will work as agreed, or you will get your money back.
              </p>
            </center>
            <center className={clsx("container", styles2.mycontainer2)}>
              <h3 style={{ textAlign: "center" }}>The App Development Path</h3>
              <p>
                If you want to get a high quality app running fast, we have a
                low risk and high impact path.
              </p>
              <p>
                We have developed apps in as little as <b>2 weeks !!</b>
              </p>
              <p>
                Our proven path to fast & affordable app development is a result
                of following a recipe that works because it splits roles between
                professionals for speed and reliability.
              </p>
              <p>
                We develop in a few quick stages. Get a breakdown of the stages
                of app development in terms of :
                <ul>
                  <li>Cost estimate</li>
                  <li>Time estimate</li>
                  <li>Documents you can expect</li>
                  <li>Capability you can expect</li>
                </ul>
              </p>
              <center>
                <button
                  type="button"
                  className="button"
                  onClick={() => {
                    setInitialEstimate();
                  }}
                >
                  Get Time, Cost & Sample Docs
                </button>
              </center>
            </center>
          </div>
        ) : (
          Parser(patch)
        )}
        {Pricing.verifyCount === 0 && <ContactUs />}
      </div>
    </>
  );
};

export default PricingMoreLayout;
